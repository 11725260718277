<template>
  <div class="ConnectIntegrationMain w-100">
    <div class="h-100" v-if="isStep1">
      <div class="d-flex justify-content-center align-items-center h-100">
        <div class="Step1Container">
          <div
            class="integrationImgContainer d-flex justify-content-center align-items-center"
          >
            <div class="integrationImg">
              <LazyImage
                :src="`https://images.quizell.com/website%2Fquizellicon.svg`"
                :key="'Quizell'"
                style="width: 100%; height: 100%; border-radius: 8px"
                :alt="'Quizell'"
                :title="'Quizell'"
              />
            </div>
            <div class="mx-3">
              <svg
                width="72"
                height="8"
                viewBox="0 0 72 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="4" cy="4" r="4" fill="#CBCDDC" />
                <circle cx="68" cy="4" r="4" fill="#CBCDDC" />
                <rect x="14" y="3" width="8" height="2" fill="#CBCDDC" />
                <rect x="26" y="3" width="8" height="2" fill="#CBCDDC" />
                <rect x="38" y="3" width="8" height="2" fill="#CBCDDC" />
                <rect x="50" y="3" width="8" height="2" fill="#CBCDDC" />
              </svg>
            </div>
            <div class="integrationImg">
              <LazyImage
                :src="`${integration.image}`"
                :key="integration.image"
                style="width: 100%; height: 100%; border-radius: 14px"
                :alt="integration.title"
                :title="integration.title"
                class="rounded"
              />
            </div>
          </div>
          <div class="integrationLogin mt-3">
            <p v-if="integration.title">
              Connect to your {{ integration.title }}
            </p>
          </div>
          <div class="authenticateDiv mt-3">
            <div
              class="d-flex align-items-center justify-content-center loadingDiv"
              v-if="isCodeUpdateLoading"
            >
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading... </span>
              </div>
              <span class="ml-2">Updating {{ integration.title }} </span>
            </div>
            <div v-else>
              <div class="authBtnDiv d-flex justify-content-center">
                <button class="authBtn" @click="openAuth">
                  Authorize to {{ integration.title }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100" v-if="isStep2">
      <div class="w-100 h-100 d-flex justify-content-center">
        <div class="Step2Container">
          <div class="title">
            <p>Complete {{ integration.title }} details</p>
          </div>
          <div class="AppFields mt-5">
            <div class="mb-4" v-for="(field, index) in appFields" :key="index">
              <div class="inputContainer">
                <p class="fieldTitle">
                  {{ field.title
                  }}<span
                    class="text-danger"
                    v-if="field.type == 'text' || field.required"
                    >*</span
                  >
                </p>
                <div class="textContainer" v-if="field.type == 'text'">
                  <div
                    class="TextInput d-flex align-items-center py-2"
                    :class="field.help_title ? 'mb-2' : null"
                  >
                    <input
                      type="text"
                      class="mx-2 flex-grow-1"
                      v-model="field.value"
                    />
                  </div>
                  <span
                    v-if="field.help_title && field.help_url"
                    @click="openHelpUrl(field.help_url)"
                    class="fieldTitle ml-2"
                    style="font-size: 13px; cursor: pointer"
                    >{{ field.help_title }}</span
                  >
                </div>
                <div class="textContainer" v-if="field.type == 'select'">
                  <div
                    class="TextInput d-flex align-items-center py-2"
                    :class="field.help_title ? 'mb-2' : null"
                  >
                    <select
                      name=""
                      class="mx-2 flex-grow-1"
                      v-model="field.value"
                      id=""
                    >
                      <option disabled value="" selected>
                        Select {{ field.title }}
                      </option>
                      <option
                        :value="option.value"
                        v-for="(option, index) in field.options"
                        :key="index"
                      >
                        {{ option.title }}
                      </option>
                    </select>
                  </div>
                  <span
                    v-if="field.help_title && field.help_url"
                    @click="openHelpUrl(field.help_url)"
                    class="fieldTitle ml-2"
                    style="font-size: 13px; cursor: pointer"
                    >{{ field.help_title }}</span
                  >
                </div>
                <div class="textContainer" v-if="field.type == 'radio'">
                  <div class="d-flex flex-wrap align-items-center gap">
                    <div
                      class="TextInput d-flex align-items-center py-2"
                      v-for="(option, index) in field.options"
                      :key="index"
                      :class="field.help_title ? 'mb-2' : null"
                    >
                      <b-form-radio
                        :name="field.key"
                        v-model="field.value"
                        class="radio-button cursor-pointer mx-2"
                        :value="option.value"
                        >{{ option.title }}</b-form-radio
                      >
                    </div>
                  </div>
                  <span
                    v-if="field.help_title && field.help_url"
                    @click="openHelpUrl(field.help_url)"
                    class="fieldTitle ml-2"
                    style="font-size: 13px; cursor: pointer"
                    >{{ field.help_title }}</span
                  >
                </div>
                <div class="textContainer" v-if="field.type == 'checkbox'">
                  <div class="d-flex flex-wrap align-items-center gap">
                    <div
                      class="TextInput d-flex align-items-center py-2"
                      v-for="(option, index) in field.options"
                      :key="index"
                      :class="field.help_title ? 'mb-2' : null"
                    >
                      <b-form-checkbox
                        :name="field.key"
                        v-model="field.value"
                        class="radio-button cursor-pointer mx-2"
                        :value="option.value"
                        >{{ option.title }}</b-form-checkbox
                      >
                    </div>
                  </div>
                  <span
                    v-if="field.help_title && field.help_url"
                    @click="openHelpUrl(field.help_url)"
                    class="fieldTitle ml-2"
                    style="font-size: 13px; cursor: pointer"
                    >{{ field.help_title }}</span
                  >
                </div>
                <!--  -->
                <div
                  class="toggleContainer"
                  v-if="field.type == 'toggle_switch'"
                >
                  <b-form-checkbox
                    v-model="field.value"
                    name="check-button"
                    class="ml-2"
                    switch
                  >
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="SaveBtnDiv mt-5 d-flex justify-content-center">
            <button
              class="btn saveBtn"
              :disabled="!isStep2Valid"
              @click="SaveConnection"
            >
              <span v-if="!saveFieldsLoading">Save</span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="h-100" v-if="isStep3">
      <div class="w-100 h-100">
        <div class="Step3Container">
          <div class="title">
            <p>Choose Quizzes to connect</p>
          </div>

          <div class="h-100 QuesDiv mt-3">
            <div class="container" v-if="quizLoading">
              <div class="row m-0">
                <div
                  class="col-12 col-sm-12 col-md-6 col-lg-4 mb-3"
                  v-for="index in 3"
                  :key="index"
                >
                  <div class="quizMainDev bg-preload" style="height: 300px">
                    <div class="into-preload"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="" v-else>
              <div class="" v-if="Quizzes.length">
                <div class="row m-0 py-4 QuizessContainer">
                  <div
                    class="col-12 col-sm-12 col-md-6 col-lg-4 mb-3"
                    v-for="(quiz, index) in Quizzes"
                    :key="index"
                  >
                    <div class="quizMainDev" style="background: #ffffff">
                      <div>
                        <div
                          class="topSection position-relative"
                          style="
                            height: 300px;
                            width: 100%;
                            border-radius: 12px;
                            background: #fff;
                          "
                        >
                          <GetStartedPagePreviewComp
                            v-if="quiz.first_page.value == 'getStartedPage'"
                            :key="quiz.id + `getStarted`"
                            :height="300"
                            :width="200"
                            :mode="'firstPage'"
                            :pageDesign="quiz.first_page.pageDesign"
                            :order="quiz.first_page.order"
                          />
                          <QuestionLeadsStripePage
                            v-else
                            :key="quiz.id + `Question`"
                            :height="300"
                            :width="200"
                            :mode="'firstPage'"
                            :CurrentPageValue="quiz.first_page.value"
                            :pageDesign="quiz.first_page.pageDesign"
                            :order="quiz.first_page.order"
                          />

                          <div class="checboxDiv">
                            <b-form-checkbox
                              :id="`${quiz.id}`"
                              v-model="selectIntegrationQuiz"
                              :name="`${quiz.id}`"
                              :value="quiz.id"
                            ></b-form-checkbox>
                          </div>
                        </div>
                        <div
                          class="p-2 d-flex justify-content-between align-items-center"
                        >
                          <p
                            class="font-weight-bold text-center m-0"
                            style="font-size: 16px; cursor: pointer"
                          >
                            {{ quiz.title }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="col-12 d-flex align-items-center justify-content-center"
                  >
                    <div
                      v-if="Quizzes.length < pagination.total"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <Button
                        :is-loading="quizLoading"
                        @click="getQuizess"
                        class="btn"
                        style="color: #73738d"
                        >Load More</Button
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div style="text-align: center" v-else>
                <p style="text-align: center">No Quizzes found</p>
                <div class="d-flex justify-content-center align-items-center">
                  <router-link to="/" class="CreateQuizBtn"
                    >Create your first quiz
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div
            class="SaveBtnDiv mt-2 d-flex justify-content-center"
            v-if="this.Quizzes.length"
          >
            <button
              class="btn saveBtn"
              :disabled="!this.selectIntegrationQuiz.length"
              @click="SaveConnectionQuiz"
            >
              <span v-if="!saveQuizLoading">Save</span>
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
          <div class="mt-3 d-flex justify-content-center align-items-center">
            <router-link to="/integrations/list" class="skipStep"
              >Skip this step
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    GetStartedPagePreviewComp: () =>
      import(
        /* webpackChunkName: "GetStartedPagePreviewComp" */ "../NavbarPagePreview/GetStartedPagePreviewComp.vue"
      ),
    QuestionLeadsStripePage: () =>
      import(
        /* webpackChunkName: "QuestionLeadsStripePage" */ "../NavbarPagePreview/QuestionLeadsStripePage.vue"
      ),
  },
  props: ["integration"],
  data() {
    return {
      isStep1: true,
      isStep2: false,
      isStep3: false,
      isCodeUpdateLoading: false,
      appFields: JSON.parse(this.integration.app_fields),
      // JSON.parse(this.integration.app_fields),
      saveFieldsLoading: false,
      saveQuizLoading: false,
      selectIntegrationQuiz: [],
      Quizzes: [],
      ConnectionId: null,
      quizLoading: false,
      pagination: {
        current_page: 1,
        total: 0,
        per_page: 4,
      },
    };
  },
  methods: {
    Step1View() {
      this.isStep1 = true;
      this.isStep2 = false;
      this.isStep3 = false;
    },
    Step2View() {
      this.isStep1 = false;
      this.isStep2 = true;
      this.isStep3 = false;
    },
    Step3View() {
      this.isStep1 = false;
      this.isStep2 = false;
      this.isStep3 = true;
      this.getQuizess();
    },
    handleMessage(event) {
      if (event.data && event.data.type == "integration" && event.data.code) {
        this.SaveIntegrationCode(event.data.code);
      }
    },
    openHelpUrl(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
    async SaveIntegrationCode(code) {
      try {
        this.isCodeUpdateLoading = true;
        let formData = new FormData();
        formData.append("code", code);
        formData.append("app_id", this.integration.id);
        const response = await axios.post(
          `/integrationConnectionToken`,
          formData
        );
        if (response.status == 200 && response.data.ok) {
          this.$toasted.show("Integration added!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          this.ConnectionId = response.data.connection_id;
          // If app Fields then shift to second step or save the integration
          if (this.integration.app_fields) {
            this.Step2View();
          } else {
            this.Step3View();
          }
        } else {
          this.$toasted.show("Something went wrong!", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
          this.isCodeUpdateLoading = false;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isCodeUpdateLoading = false;
      }
    },
    getSaveConnectionPayload(arr, field) {
      let value;
      for (const object of arr) {
        if (object.key == field) {
          value = object.value;
        }
      }

      if (typeof value != "undefined") {
        if (value != "") {
          return value;
        } else {
          return field == "full_status" ? false : "";
        }
      } else {
        return field == "full_status" ? false : "";
      }
    },
    async SaveConnection() {
      try {
        this.saveFieldsLoading = true;
        let data = {};
        if (this.ConnectionId) {
          data.id = this.ConnectionId;
        }
        data.app_id = this.integration.id;
        if (this.appFields) {
          (data.api_key = this.getSaveConnectionPayload(
            this.appFields,
            "api_key"
          )),
            (data.list_key = this.getSaveConnectionPayload(
              this.appFields,
              "list_key"
            )),
            (data.other_key = this.getSaveConnectionPayload(
              this.appFields,
              "other_key"
            )),
            (data.full_status = this.getSaveConnectionPayload(
              this.appFields,
              "full_status"
            ));
        } else {
          (data.app_key = ""),
            (data.list_key = ""),
            (data.other_key = ""),
            (data.full_status = false);
        }

        const response = await axios.post(`/integrationConnectionSave`, data);
        if (response.status == 200 && response.data.ok) {
          this.ConnectionId = response.data.connection_id;
          if (this.integration.title == "WooCommerce") {
            this.$router.push("/integrations/list");
          } else {
            this.Step3View();
          }
        } else {
          this.$toasted.show("Something went wrong!", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.saveFieldsLoading = false;
      }
    },
    async openAuth() {
      if (this.integration.is_oauth && this.integration.is_oauth == 1) {
        let clientID = await this.getEnvVariable(this.integration.handle_key);
        if (clientID != "") {
          const platform = window.location.origin.includes("wix")
            ? "wix"
            : "shopify";
          const redirectURL =
            this.integration.handle_key == "mail_chimp"
              ? encodeURIComponent(`${process.env.VUE_APP_API_BASE_URL}integrations/redirect/mailchimp`)
              : `${window.location.origin}/integrations/redirect/${this.integration.handle_key}`;
          let url = this.integration.oauth_url
            .replace("[CLIENT_ID]", clientID)
            .replace("[REDIRECT_URL]", redirectURL);
          let finalURL =  this.integration.handle_key == "mail_chimp" ? url+`&state=${platform}` : url
          let w = 850;
          let h = 800;
          var left = screen.width / 2 - w / 2;
          var top = screen.height / 2 - h / 2;
          window.open(
            finalURL,
            `${this.integration.title} login`,
            "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
              w +
              ", height=" +
              h +
              ", top=" +
              top +
              ", left=" +
              left
          );
        } else {
          this.$toasted.show(`Something went wrong!`, {
            theme: "toasted-primary",
            type: "error",

            position: "bottom-center",
            duration: 2000,
          });
        }
      } else {
        this.Step2View();
      }
    },
    getEnvVariable(handlekey) {
      let url = "";
      if (handlekey == "stripe") {
        url = process.env.VUE_APP_STRIPE_CLIENT_ID;
      }
      if (handlekey == "mail_chimp") {
        url = process.env.VUE_APP_MAILCHIMP_CLIENT_ID;
      }
      if (handlekey == "hubspot") {
        url = process.env.VUE_APP_HUBSPOT_CLIENT_ID;
      }

      return url;
    },
    async getQuizess() {
      this.quizLoading = true;
      try {
        let data = {
          //   _token: this.GetTokenFromMetaTag,
        };
        const response = await axios.post(`/loadQuizzes`, data);
        if (response.status == 200 && response.data.status == "success") {
          this.Quizzes = [...this.Quizzes, ...response?.data.data.quizzes.data];
          this.pagination.current_page =
            response?.data.data.quizzes.current_page + 1;
          this.pagination.total = response?.data.data.quizzes.total;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.quizLoading = false;
      }
    },
    async SaveConnectionQuiz() {
      try {
        this.saveFieldsLoading = true;
        let data = {
          quiz_id: this.selectIntegrationQuiz,
          connection_id: this.ConnectionId,
          status: true,
        };

        const response = await axios.post(
          `/integrationConnectionQuizSave`,
          data
        );
        if (response.status == 200 && response.data.ok) {
          this.$router.push("/integrations/list");
        } else {
          this.$toasted.show("Something went wrong!", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.saveFieldsLoading = false;
      }
    },
  },
  computed: {
    isStep2Valid() {
      let TextFields = this.appFields.filter(
        (field) => field.type == "text" || field.required
      );
      return TextFields.every((field) => field.value != "");
    },
  },
  mounted() {
    if (this.$route.query.code) {
      this.SaveIntegrationCode(this.$route.query.code);
    }
    window.addEventListener("message", this.handleMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage);
  },
};
</script>

<style>
.ConnectIntegrationMain {
  min-height: 70vh;
}

.Step1Container .integrationImgContainer .integrationImg {
  width: 110px;
  height: 110px;
  border-radius: 8px;
}

.Step1Container .integrationLogin {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  text-align: center;

  color: #4d4950;
}
.Step1Container .loadingDiv {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;

  color: #b3afb6;
}
.Step1Container .authBtn,
.Step2Container .saveBtn,
.Step3Container .saveBtn,
.CreateQuizBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
  gap: 10px;
  width: 265px;
  height: 48px;
  color: #ffffff;
  background: #4d1b7e;
  border-radius: 100px;
  cursor: pointer;
}
.Step1Container .authBtn:hover,
.Step2Container .saveBtn:hover,
.Step3Container .saveBtn:hover,
.CreateQuizBtn:hover {
  color: #ffffff;
}

.Step2Container .title,
.Step3Container .title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #4d4950;
}

.Step2Container .AppFields .inputContainer .fieldTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #7c8290;
}
.TextInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
}

.TextInput input,
.TextInput select {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
}
.TextInput input:focus,
.TextInput select:focus {
  border: none;
  outline: none;
}

.Step3Container .QuizessContainer {
  max-height: 60vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.Step3Container .quizMainDev {
  box-shadow: rgb(10 4 17 / 15%) 0px 4px 10px;
  border-radius: 12px;
}
.Step3Container .quizMainDev .checboxDiv {
  position: absolute;
  top: 10px;
  right: 10px;
}
.Step3Container .quizMainDev:hover {
  transition: transform 200ms ease;
  transform: scale(1.02);
}
.Step3Container .quizMainDev .topSection {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.skipStep {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;

  color: #7c8290;
}
.skipStep:hover {
  color: #7c8290;
}
.radio-button
  >>> .custom-control-input:checked
  ~ .custom-control-label::before {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}
.radio-button >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--secondary-color);
}
</style>
